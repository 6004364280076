import {Panes, Select} from '@management-ui/core';
import numeral from 'numeral';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({subscription = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [tiers, setTiers] = useState([]);
  const tiersLoaded = useRef(false);

  useEffect(() => {
    if (!tiersLoaded.current) {
      tiersLoaded.current = true;
      services.subscriptionTier.getTiers({}, 1, 500).then((retrieved) => {
        setTiers(retrieved.data.map((
          {
            id,
            name,
            price
          }
        ) => ({
          title: `${name}${price ? ` (£${numeral(price).format('0,0.00')})` : ''}`,
          value: id
        })));
      }).catch(() => null);
    }
  }, [services]);

  return (
    <Panes
      entity={subscription}
      prefix={prefix}
      panes={[
        {
          title: 'Subscription',
          fields: [
            <Select
              name="tier_id"
              prefix={prefix}
              label="Subscription Tier"
              options={tiers}
            />
          ]
        }
      ]}
      title=""
    />
  );
}

export default Fields;
