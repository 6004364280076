import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

const CoreFields = ({affiliate = {}, prefix = '', multiplePanes = true, children}) => {
  const {setValue, /** @var {function(string): string} */watch} = useFormContext();

  const types = useOptions('affiliateTypes', useCallback(types => types.map(({name, id}) => ({
    title: name,
    value: id
  })), []));
  const typeName = useMemo(() => `${prefixWithSeparator(prefix)}type_id`, [prefix]);
  const type = watch(typeName);

  useEffect(() => {
    if (affiliate) {
      if (types) {
        if (!type) {
          if (affiliate.type) {
            setValue(typeName, affiliate.type.id);
          }
        }
      }
    }
  }, [affiliate, types, typeName, type, setValue]);

  return (
    <Panes
      entity={affiliate}
      prefix={prefix}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name for this affiliate'}}
            />,
            <Select
              name="type_id"
              prefix={prefix}
              options={types ?? []}
              label="Type"
            />,
            <TextField
              name="description"
              prefix={prefix}
              label="Description"
              fieldProps={{multiline: true, rows: 12}}
            />,
            <TextField
              name="promotions"
              prefix={prefix}
              label="Promotions"
              fieldProps={{multiline: true, rows: 12}}
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Affiliate Details'}
    >{children}</Panes>
  );
};

export default CoreFields;
