import * as qs from 'qs';
import {API} from './API';

export class EventService {
  async getTableEvents(params, additional = {}, resource = 'event') {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getEvents(query, page, length, resource);
  }

  async getEvents(query, page, length, resource = 'event') {
    return (await API.getConnection())
      .get('events', {
        params: {...query, ...{page, length, resource}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getEvent(id, archive = false) {
    return (await API.getConnection())
      .get(`events/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveEvent(data, resource = 'event') {
    let method = 'post';
    let url = 'events';
    if (data.id) {
      method = 'put';
      url = `events/${data.id}`;
    }
    return (await API.getConnection())
      [method](`${url}?resource=${resource}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async uploadImage(event, file) {
    const body = new FormData();
    body.append('image', file);
    return (await API.getConnection())
      .post(
        `events/${event.id}/upload`,
        body,
        {headers: {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}}
      ).then(response => response.data.path).catch(error => API.handleError(error));
  }

  async deleteEvent(event) {
    return (await API.getConnection())
      .delete(`events/${event.id}`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async restoreEvent(event) {
    return (await API.getConnection())
      .put(`events/${event.id}/restore`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }
}
