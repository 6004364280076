import {DataTable, SquareButton} from '@management-ui/core';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Grid} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import TownForm from '../../forms/TownForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const goToDetail = useCallback((town) => {
    history.push(reverse(routes.towns.detail, {id: town.id}));
  }, [history]);

  const handleRestore = useCallback((selected) => {
    setLoading(true);
    services.town.restoreTown(selected).then(() => {
      setLoading(false);
      tableRef.current.refresh();
    }).catch(() => setLoading(false));
  }, [services]);

  return (
    <DataTable
      ref={tableRef}
      title="Towns"
      options={{search: false}}
      tableProps={{isLoading: loading}}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Country', field: 'country', sorting: false, render: item => item.country ? item.country.name : '-'},
        ...(archive ? [
          {
            title: 'Restore',
            field: 'restore',
            sorting: false,
            width: 120,
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (item) => (
              <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                <SquareButton
                  tooltip="Restore"
                  icon={<RestoreFromTrashIcon/>}
                  onClick={() => handleRestore(item)}
                />
              </Grid>
            )
          }
        ] : [])
      ], [archive, handleRestore])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.town.getTableTowns(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, archive])}
      newForm={archive ? null : {
        title: 'Add New Town',
        render: (props) => <TownForm town={{}} {...props}/>,
        onSaved: goToDetail
      }}
      onRowClick={archive ? null : goToDetail}
    />
  );
};

export default Table;
