import {DataTable, dateTime, name} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Table = ({user = null, affiliate = null}) => {
  const services = useContext(ServiceContext);

  return (
    <DataTable
      title="Check-ins"
      options={{search: false, sorting: false, draggable: false}}
      columns={useMemo(() => [
        ...(user ? [] : [{title: 'User', field: 'user', render: data => name(data.user)}]),
        ...(affiliate ? [] : [{title: 'Affiliate', field: 'affiliate', render: data => data.affiliate.name}]),
        {title: 'Date/Time', field: 'created_at', render: data => dateTime(data.created_at)},
      ], [user, affiliate])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        const filters = {};
        if (user) {
          filters.user = `${user.id}`;
        }
        if (affiliate) {
          filters.affiliate = `${affiliate.id}`;
        }
        services.checkIn.getTableCheckIns(query, filters)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, user, affiliate])}
      newForm={null}
      onRowClick={null}
    />
  );
};

export default Table;
