import React, {createContext} from 'react';
import {AddOnService} from '../services/AddOnService';
import {AffiliateService} from '../services/AffiliateService';
import {AffiliateTypeService} from '../services/AffiliateTypeService';
import {AuthService} from '../services/AuthService';
import {CheckInService} from '../services/CheckInService';
import {CompetitionService} from '../services/CompetitionService';
import {CountryService} from '../services/CountryService';
import {DestinationService} from '../services/DestinationService';
import {EventService} from '../services/EventService';
import {EventTypeService} from '../services/EventTypeService';
import {ServiceProvider} from '../services/ServiceProvider';
import {SettingsService} from '../services/SettingsService';
import {SubscriptionTierService} from '../services/SubscriptionTierService';
import {TownService} from '../services/TownService';
import {UserService} from '../services/UserService';

export const ServiceContext = createContext({});

export default function Services({children}) {
  const services = {
    addOn: new AddOnService(),
    affiliate: new AffiliateService(),
    affiliateType: new AffiliateTypeService(),
    auth: new AuthService(),
    checkIn: new CheckInService(),
    competition: new CompetitionService(),
    country: new CountryService(),
    destination: new DestinationService(),
    event: new EventService(),
    eventType: new EventTypeService(),
    settings: new SettingsService(),
    subscriptionTier: new SubscriptionTierService(),
    town: new TownService(),
    user: new UserService()
  };
  ServiceProvider.setServices(services);
  return (
    <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
  );
}
