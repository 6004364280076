import {Checkbox, DateField, Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import validator from 'email-validator';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';

export default function Fields(
  {
    user = {},
    prefix = '',
    multiplePanes = true,
    children
  }
) {
  const services = useContext(ServiceContext);
  const {/** @var {function(string): string} */watch} = useFormContext();
  const password = watch(`${prefixWithSeparator(prefix)}password`);
  const confirm = watch(`${prefixWithSeparator(prefix)}confirmPassword`);
  const [tiers, setTiers] = useState([]);
  const tiersLoaded = useRef(false);

  useEffect(() => {
    if (!tiersLoaded.current) {
      tiersLoaded.current = true;
      services.subscriptionTier.getTiers({}, 1, 500).then((retrieved) => {
        setTiers(retrieved.data.map((
          {
            id,
            name,
            price
          }
        ) => ({
          title: `${name}${price ? ` (£${numeral(price).format('0,0.00')})` : ''}`,
          value: id
        })));
      }).catch(() => null);
    }
  }, [services]);

  const handleReset = useCallback((subject) => {
    if (subject.tier) {
      subject.tier_id = subject.tier.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={user}
      onReset={handleReset}
      prefix={prefix}
      panes={[
        {
          title: 'Personal Details',
          fields: [
            <TextField
              name="first_name"
              prefix={prefix}
              label="First Name"
              rules={{required: 'Please enter a first name'}}
            />,
            <TextField
              name="last_name"
              prefix={prefix}
              label="Last Name"
              rules={{required: 'Please enter a last name'}}
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                required: 'Please enter a email address',
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              autoComplete="email"
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone Number"
            />,
            <TextField
              name="location"
              prefix={prefix}
              label="Location"
            />,
            <DateField
              name="date_of_birth"
              prefix={prefix}
              label="Date of Birth"
            />,
            <TextField
              name="facebook"
              prefix={prefix}
              label="Facebook"
            />,
            <TextField
              name="twitter"
              prefix={prefix}
              label="Twitter"
            />,
            <TextField
              name="instagram"
              prefix={prefix}
              label="Instagram"
            />,
            <Select
              name="tier_id"
              prefix={prefix}
              label="Subscription Tier"
              options={tiers}
            />
          ]
        }, {
          title: 'Access Details',
          fields: [
            <TextField
              name="password"
              prefix={prefix}
              label="Password"
              rules={{
                ...(!user.id ? {required: 'Please enter a password'} : {}),
                validate: useCallback((value) => {
                  if (value) {
                    return value === confirm ? true : 'Please make sure the passwords match';
                  }
                  return true;
                }, [confirm])
              }}
              fieldProps={{
                type: 'password',
                autoComplete: 'password'
              }}
            />,
            <TextField
              name="confirmPassword"
              prefix={prefix}
              label="Confirm Password"
              rules={{
                validate: useCallback((value) => {
                  if (password) {
                    return value ? true : 'Please confirm the password';
                  }
                  return true;
                }, [password])
              }}
              fieldProps={{
                type: 'password',
                autoComplete: 'password'
              }}
            />,
            <Checkbox
              name="is_active"
              prefix={prefix}
              label="Active User?"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'User Details'}
    >{children}</Panes>
  );
}
