import {ConfirmationDialog, FormDialog, ReorderingPanel} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import AffiliateImageForm from '../../forms/AffiliateImageForm';

const Images = ({affiliate, onAffiliateUpdated}) => {
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState({});

  const formatImages = useCallback((list) => (
    list.map((image) => ({
      entity: image,
      attributes: [<img src={image.url} width={200} alt=""/>]
    }))
  ), []);

  useEffect(() => {
    setImages(formatImages(affiliate ? affiliate.images : []));
  }, [affiliate, formatImages]);

  const handleReorder = useCallback((reordered) => {
    setImages(formatImages(reordered));
    return services.affiliate.reorderImages(affiliate, reordered.map((image, index) => ({
      id: image.id,
      index: index + 1
    })));
  }, [affiliate, services, formatImages]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.affiliate.deleteImage(affiliate, selected).then((updated) => {
        setLoading(false);
        onAffiliateUpdated(updated);
      }).catch(() => setLoading(false));
    }
  }, [affiliate, onAffiliateUpdated, services, selected]);

  const handleSaved = useCallback((updated) => {
    onAffiliateUpdated(updated);
    setShowEdit(false);
  }, [onAffiliateUpdated]);

  return (
    <>
      <ReorderingPanel
        title="Images"
        items={images}
        loading={loading}
        onLoading={setLoading}
        controls={[
          {
            title: 'Delete Image',
            icon: <DeleteIcon/>,
            onClick: (section) => setSelected(section) || setShowDelete(true)
          }
        ]}
        onNew={() => setSelected(null) || setShowEdit(true)}
        onReorder={handleReorder}
        onReordered={handleSaved}
      />
      <FormDialog
        title={'Add New Image'}
        open={showEdit}
        maxWidth="md"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <AffiliateImageForm
            {...props}
            affiliate={affiliate}
            image={{}}
            onSaved={handleSaved}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete Image"
        message="Are you sure you want to delete this image?"
        open={showDelete}
        onClose={handleDelete}
      />
    </>
  );
}

export default Images;
