import {DataTable, SquareButton} from '@management-ui/core';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Grid} from '@mui/material';
import {reverse} from 'named-urls';
import numeral from 'numeral';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import AddOnForm from '../../forms/AddOnForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const goToDetail = useCallback((addOn) => {
    history.push(reverse(routes.addOns.detail, {id: addOn.id}));
  }, [history]);

  const handleRestore = useCallback((selected) => {
    setLoading(true);
    services.addOn.restoreAddOn(selected).then(() => {
      setLoading(false);
      tableRef.current.refresh();
    }).catch(() => setLoading(false));
  }, [services]);

  // noinspection JSValidateTypes
  return (
    <DataTable
      ref={tableRef}
      title="Add-ons"
      options={{search: false}}
      tableProps={{isLoading: loading}}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Price', field: 'price', render: data => `£${numeral(data.price).format('0,0.00')}`},
        ...(archive ? [
          {
            title: 'Restore',
            field: 'restore',
            sorting: false,
            width: 120,
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (item) => (
              <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                <SquareButton
                  tooltip="Restore"
                  icon={<RestoreFromTrashIcon/>}
                  onClick={() => handleRestore(item)}
                />
              </Grid>
            )
          }
        ] : [])
      ], [archive, handleRestore])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.addOn.getTableAddOns(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, archive])}
      newForm={archive ? null : {
        title: 'Add New Add-on',
        render: (props) => <AddOnForm addOn={{}} {...props}/>,
        onSaved: goToDetail
      }}
      onRowClick={archive ? null : goToDetail}
    />
  );
};

export default Table;
