import {DataTable, name} from '@management-ui/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import UserForm from '../../forms/UserForm';

const Table = ({tier = null, admin = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const initial = useRef(true);
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    } else {
      tableRef.current.refresh();
    }
  }, [admin, tier]);

  const goToDetail = useCallback((user) => {
    history.push(reverse(routes[admin ? 'administrators' : 'users'].detail, {id: user.id}));
  }, [history, admin]);

  return (
    <DataTable
      ref={tableRef}
      title={admin ? 'Administrators' : 'Users'}
      columns={useMemo(() => [
        {title: 'Name', field: 'name', render: data => name(data)},
        {title: 'Email', field: 'email'},
        {title: 'Active?', field: 'is_active', render: data => <YesNo value={data.is_active}/>},
      ], [])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.user.getTableUsers(query, {
          is_admin: admin ? '1' : '0',
          ...(tier ? {tier: tier.id} : {})
        })
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, admin, tier])}
      newForm={tier ? null : {
        title: `Add New ${admin ? 'Administrators' : 'Users'}`,
        render: (props) => <UserForm user={{}} admin={admin} {...props}/>,
        onSaved: goToDetail
      }}
      onRowClick={goToDetail}
    />
  );
};

export default Table;
