import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import numeral from 'numeral';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import FileUploader from '../../../../components/FileUploader';
import {ServiceContext} from '../../../../components/Services';
import Text from '../../../../components/Text';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import AddOnForm from '../../forms/AddOnForm';
import Wrapper from './Wrapper';

const AddOn = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({addOn: 'Sorry the add-on could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['core', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [addOn, setAddOn] = useState(null);

  const loadAddOn = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.addOn.getAddOn(id).then(retrieved => {
        setAddOn(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('addOn', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadAddOn(match.params?.id);
    }
  }, [history, match, addOn, loadAddOn]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (addOn) {
      header = addOn.name;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [addOn]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.addOn.deleteAddOn(addOn).then(() => {
        setLoading(false);
        history.push(routes.addOns.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, addOn]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('core', false);
    if (updated) {
      setAddOn(updated);
    } else if (addOn?.id) {
      loadAddOn(addOn.id);
    }
  }, [toggleDialog, setAddOn, loadAddOn, addOn]);

  const handleUpload = useCallback((image) => (
    services.addOn.uploadImage(addOn, image)
  ), [services, addOn]);

  const handleUploaded = useCallback((path) => {
    setAddOn({...addOn, image: path})
  }, [addOn]);

  return (
    <Wrapper title={title} addOn={addOn} loading={loading} crumbs={crumbs} errors={errors}>
      {addOn?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Core Details"
              actions={[
                {title: 'Edit Add-on', icon: <EditIcon/>, onClick: () => toggleDialog('core', true)},
                {title: 'Delete Add-on', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Name', value: addOn.name},
                {title: 'Description', value: addOn.description ? <Text text={addOn.description}/> : '-'},
                {title: 'Price', value: addOn.price ? `£${numeral(addOn.price).format('0,0.00')}` : '-'},
                {title: 'Requires Delivery?', value: <YesNo value={addOn.requires_delivery}/>},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Add-on"
                    open={openDialogs['core'] ?? false}
                    onClose={() => toggleDialog('core', false)}
                    render={(props) => (
                      <AddOnForm
                        {...props}
                        addOn={addOn}
                        fieldSet="core"
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete Add-on"
                    message="Are you sure you want to delete this add-on?"
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Image"
              collapseChildrenHeight={false}
            >
              <Box paddingX={2}>
                <FileUploader
                  title=""
                  image={addOn.image}
                  prompt="Drop an image here or click to select one from your computer"
                  onUpload={handleUpload}
                  onUploaded={handleUploaded}
                />
              </Box>
            </DetailPane>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(addOn.created_at)},
                {title: 'Last Updated', value: dateTime(addOn.updated_at)}
              ]}
            />,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default AddOn;
