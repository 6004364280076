import {BaseForm} from '@management-ui/core';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const EventForm = forwardRef(({event, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={event}
      type="event"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, type_id, title, date, time, affiliate_id, town_id, content}) => services.event.saveEvent({
        id,
        type_id,
        title,
        date: date ? moment(date).format('YYYY-MM-DD') : null,
        time,
        affiliate_id,
        town_id,
        content
      })}
      onSaved={onSaved}
    />
  );
});

export default EventForm;
