import {Breadcrumbs, ErrorPopup, Title} from '@management-ui/core';
import React from 'react';
import routes from '../../../../routes';

const Wrapper = ({title, loading, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading}/>
      {children}
      <ErrorPopup errors={['user']} messages={errors}/>
    </>
  );
}

Wrapper.administratorCrumbs = [
  {title: 'Administrators', link: routes.administrators.index}
];

Wrapper.userCrumbs = [
  {title: 'Users', link: routes.users.index}
];

export default Wrapper;
