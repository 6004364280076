import {Checkbox, Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({addOn = {}, prefix = ''}) => (
  <Panes
    entity={addOn}
    prefix={prefix}
    panes={[
      {
        title: 'Add-on Details',
        fields: [
          <TextField
            name="name"
            prefix={prefix}
            label="Name"
            rules={{required: 'Please enter a name'}}
          />,
          <TextField
            name="description"
            prefix={prefix}
            label="Description"
            fieldProps={{multiline: true, rows: 12}}
          />,
          <TextField
            name="price"
            prefix={prefix}
            label="Price"
          />,
          <Checkbox
            name="requires_delivery"
            prefix={prefix}
            label="Requires Delivery?"
          />
        ]
      },
      {
        title: '',
        fields: []
      }
    ]}
    title={null}
  />
);

export default Fields;
