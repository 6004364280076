import * as qs from 'qs';
import {setUser} from '../store/actions/auth';
import {API} from './API';

export class UserService {
  async getTableUsers(params, additional = {}, resource = 'user') {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getUsers(query, page, length, resource);
  }

  async getUsers(query, page, length, resource = 'user') {
    return (await API.getConnection())
      .get('users', {
        params: {...query, ...{page, length, resource}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getUser(id, archive = false) {
    return (await API.getConnection())
      .get(`users/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveUser(data, resource = 'user') {
    let method = 'post';
    let url = 'users';
    if (data.id) {
      method = 'put';
      url = `users/${data.id}`;
    }
    return (await API.getConnection())
      [method](`${url}?resource=${resource}`, data)
      .then(response => {
        const user = response.data.data;
        if (user.id === API.store.getState()['auth'].user.id) {
          API.store.dispatch(setUser(user));
        }
        return user;
      })
      .catch(error => API.handleError(error));
  }

  async uploadImage(user, file) {
    const body = new FormData();
    body.append('image', file);
    return (await API.getConnection())
      .post(
        `users/${user.id}/upload`,
        body,
        {headers: {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}}
      ).then(response => response.data.path).catch(error => API.handleError(error));
  }

  async startSubscription(user, tierID, success, cancelled) {
    return (await API.getConnection())
      .post(`users/${user.id}/start`, {tier_id: tierID, success, cancelled})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async cancelSubscription(user) {
    return (await API.getConnection())
      .post(`users/${user.id}/cancel`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async toggleAddOn(user, addOn) {
    return (await API.getConnection())
      .post(`users/${user.id}/add-ons`, {add_on_id: addOn.id})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteUser(user) {
    return (await API.getConnection())
      .delete(`users/${user.id}`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }
}
