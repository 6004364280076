import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Paper} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Text from '../../../../components/Text';
import routes from '../../../../routes';
import AffiliateForm from '../../forms/AffiliateForm';
import CheckIns from '../CheckIns/Table';
import Images from './Images';
import Wrapper from './Wrapper';

const Affiliate = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({affiliate: 'Sorry the affiliate could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['core', 'location', 'contact', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [affiliate, setAffiliate] = useState(null);

  const loadAffiliate = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.affiliate.getAffiliate(id).then(retrieved => {
        setAffiliate(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('affiliate', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadAffiliate(match.params?.id);
    }
  }, [history, match, affiliate, loadAffiliate]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (affiliate) {
      header = affiliate.name;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [affiliate]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.affiliate.deleteAffiliate(affiliate).then(() => {
        setLoading(false);
        history.push(routes.affiliates.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, affiliate]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('core', false);
    toggleDialog('location', false);
    toggleDialog('contact', false);
    if (updated) {
      setAffiliate(updated);
    } else if (affiliate?.id) {
      loadAffiliate(affiliate.id);
    }
  }, [toggleDialog, setAffiliate, loadAffiliate, affiliate]);

  return (
    <Wrapper title={title} affiliate={affiliate} loading={loading} crumbs={crumbs} errors={errors}>
      {affiliate?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Core Details"
              actions={[
                {title: 'Edit Core Details', icon: <EditIcon/>, onClick: () => toggleDialog('core', true)},
                {title: 'Delete Affiliate', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Name', value: affiliate.name},
                {title: 'Type', value: affiliate.type?.name ?? '-'},
                {title: 'Description', value: affiliate.description ? <Text text={affiliate.description}/> : '-'},
                {title: 'Promotions', value: affiliate.promotions ? <Text text={affiliate.promotions}/> : '-'}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Core Details"
                    open={openDialogs['core'] ?? false}
                    onClose={() => toggleDialog('core', false)}
                    render={(props) => (
                      <AffiliateForm
                        {...props}
                        affiliate={affiliate}
                        fieldSet="core"
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete Affiliate"
                    message="Are you sure you want to delete this affiliate?"
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(affiliate.created_at)},
                {title: 'Last Updated', value: dateTime(affiliate.updated_at)}
              ]}
            />,
          ]}/>

          <Paper component={Box} marginTop={2} overflow="hidden">
            <Images affiliate={affiliate} onAffiliateUpdated={setAffiliate}/>
          </Paper>

          <DetailColumns columns={[

            <DetailPane
              title="Location"
              actions={[
                {title: 'Edit Location', icon: <EditIcon/>, onClick: () => toggleDialog('location', true)},
              ]}
              details={[
                {title: 'Address Line 1', value: affiliate.address1 ?? '-'},
                {title: 'Address Line 2', value: affiliate.address2 ?? '-'},
                {title: 'Town / City', value: affiliate.town?.name ?? '-'},
                {title: 'Region', value: affiliate.region ?? '-'},
                {title: 'Postcode', value: affiliate.postcode ?? '-'},
                {title: 'Country', value: affiliate.town?.country ? affiliate.town.country.name : '-'},
                {
                  title: 'Coordinates',
                  value: `${!!affiliate.latitude ? affiliate.latitude : '-'}, ${!!affiliate.longitude ? affiliate.longitude : '-'}`
                },
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Location"
                    open={openDialogs['location'] ?? false}
                    onClose={() => toggleDialog('location', false)}
                    render={(props) => (
                      <AffiliateForm
                        {...props}
                        affiliate={affiliate}
                        fieldSet="location"
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
              ]}
            />,

            <DetailPane
              title="Contact & Social"
              actions={[
                {title: 'Edit Contact & Social', icon: <EditIcon/>, onClick: () => toggleDialog('contact', true)},
              ]}
              details={[
                {title: 'Telephone', value: affiliate.phone ?? '-'},
                {title: 'Email Address', value: affiliate.email ?? '-'},
                {title: 'Website', value: affiliate.website ?? '-', link: affiliate.website ?? null},
                {title: 'Facebook', value: affiliate.facebook ?? '-', link: affiliate.facebook ?? null},
                {title: 'Twitter', value: affiliate.twitter ?? '-', link: affiliate.twitter ?? null},
                {title: 'Instagram', value: affiliate.instagram ?? '-', link: affiliate.instagram ?? null},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Contact & Social"
                    open={openDialogs['contact'] ?? false}
                    onClose={() => toggleDialog('contact', false)}
                    render={(props) => (
                      <AffiliateForm
                        {...props}
                        affiliate={affiliate}
                        fieldSet="contact"
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
              ]}
            />,
          ]}/>

          <Paper component={Box} marginTop={2}>
            <CheckIns affiliate={affiliate}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Affiliate;
