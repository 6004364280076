import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadAddOns, loadAffiliateTypes, loadCountries, loadEventTypes, loadTowns} from './store/actions/options';

const OPTION_LOADERS = {
  countries: loadCountries,
  towns: loadTowns,
  addOns: loadAddOns,
  affiliateTypes: loadAffiliateTypes,
  eventTypes: loadEventTypes,
};

let LOADING = [];

export function useOptions(type, formatter) {
  const list = useSelector(state => state['options'][type]);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (list.length < 1) {
      if (LOADING.indexOf(type) < 0) {
        LOADING.push(type);
        dispatch(OPTION_LOADERS[type]());
      }
    } else {
      const index = LOADING.indexOf(type);
      if (index >= 0) {
        LOADING.splice(index, 1);
        LOADING = [...LOADING];
      }
    }
  }, [list, type, dispatch]);

  useEffect(() => {
    setOptions(formatter ? formatter(list) : list);
  }, [list, formatter]);

  return options;
}
