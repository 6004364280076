import * as qs from 'qs';
import {API} from './API';

export class SubscriptionTierService {
  async getTableTiers(params, additional = {}, resource = 'tier') {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTiers(query, page, length, resource);
  }

  async getTiers(query, page, length, resource = 'tier') {
    return (await API.getConnection())
      .get('tiers', {
        params: {...query, ...{page, length, resource}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getTier(id, archive = false) {
    return (await API.getConnection())
      .get(`tiers/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveTier(data, resource = 'tier') {
    let method = 'post';
    let url = 'tiers';
    if (data.id) {
      method = 'put';
      url = `tiers/${data.id}`;
    }
    return (await API.getConnection())
      [method](`${url}?resource=${resource}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reorderTiers(tiers) {
    return (await API.getConnection())
      .put(`tiers`, {tiers})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteTier(tier) {
    return (await API.getConnection())
      .delete(`tiers/${tier.id}`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async restoreTier(tier) {
    return (await API.getConnection())
      .put(`tiers/${tier.id}/restore`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }
}
