import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

const LocationFields = ({affiliate = {}, prefix = '', multiplePanes = true, children}) => {
  const {setValue, /** @var {function(string): string} */watch} = useFormContext();

  const towns = useOptions('towns', useCallback(towns => towns.map(({name, id, country}) => ({
    title: country ? `${name} (${country.name})` : name,
    value: id
  })), []));
  const townName = useMemo(() => `${prefixWithSeparator(prefix)}town_id`, [prefix]);
  const town = watch(townName);

  useEffect(() => {
    if (affiliate) {
      if (towns) {
        if (!town) {
          if (affiliate.town) {
            setValue(townName, affiliate.town.id);
          }
        }
      }
    }
  }, [affiliate, towns, townName, town, setValue]);

  return (
    <Panes
      entity={affiliate}
      prefix={prefix}
      panes={[
        {
          title: 'Location',
          fields: [
            <TextField
              name="address1"
              prefix={prefix}
              label="Address Line 1"
            />,
            <TextField
              name="address2"
              prefix={prefix}
              label="Address Line 2"
            />,
            <Select
              name="town_id"
              prefix={prefix}
              options={towns ?? []}
              label="Town / City"
            />,
            <TextField
              name="region"
              prefix={prefix}
              label="Region"
            />,
            <TextField
              name="postcode"
              prefix={prefix}
              label="Postcode"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Affiliate Details'}
    >{children}</Panes>
  );
};

export default LocationFields;
