import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const SubscriptionTierForm = forwardRef(({tier, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={tier}
      type="tier"
      fieldsComponent={Fields}
      onSave={({id, name, price, frequency, features}) => services.subscriptionTier.saveTier({
        id,
        name,
        price,
        frequency,
        features
      })}
      onSaved={onSaved}
    />
  );
});

export default SubscriptionTierForm;
