import * as qs from 'qs';
import {clearAddOns} from '../store/actions/options';
import {API} from './API';

export class AddOnService {
  async getTableAddOns(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getAddOns(query, page, length);
  }

  async getAddOns(query, page, length) {
    return (await API.getConnection())
      .get('add-ons', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getAddOn(id, archive = false) {
    return (await API.getConnection())
      .get(`add-ons/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveAddOn(data) {
    let method = 'post';
    let url = 'add-ons';
    if (data.id) {
      method = 'put';
      url = `add-ons/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearAddOns());
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async uploadImage(addOn, file) {
    const body = new FormData();
    body.append('image', file);
    return (await API.getConnection())
      .post(
        `add-ons/${addOn.id}/upload`,
        body,
        {headers: {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}}
      ).then(response => response.data.path).catch(error => API.handleError(error));
  }

  async deleteAddOn(addOn) {
    return (await API.getConnection())
      .delete(`add-ons/${addOn.id}`)
      .then(() => API.store.dispatch(clearAddOns()))
      .catch(error => API.handleError(error));
  }

  async restoreAddOn(addOn) {
    return (await API.getConnection())
      .put(`add-ons/${addOn.id}/restore`)
      .then(() => API.store.dispatch(clearAddOns()))
      .catch(error => API.handleError(error));
  }
}
