import {Panes, Select, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({tier = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={tier}
      prefix={prefix}
      panes={[
        {
          title: 'Tier Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="price"
              prefix={prefix}
              label="Price"
            />,
            <Select
              name="frequency"
              prefix={prefix}
              label="Frequency"
              options={[
                {title: 'One-off', value: 'One-off'},
                {title: 'Monthly', value: 'Monthly'},
                {title: 'Yearly', value: 'Yearly'}
              ]}
              rules={{required: 'Please select a frequency'}}
            />,
            <TextField
              name="features"
              prefix={prefix}
              label="Features"
              fieldProps={{multiline: true, rows:6}}
            />
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Tier Details'}
    >{children}</Panes>
  );
};

export default Fields;
