import {Authenticated} from '@management-ui/core';
import {
  Apartment,
  Archive as ArchiveIcon,
  CalendarToday,
  ContactMail,
  CreditCard,
  Dashboard as DashboardIcon,
  LocationOn,
  Map,
  Security,
  StarBorder,
  Storage,
  SwitchAccessShortcutAdd
} from '@mui/icons-material';
import {Box} from '@mui/material';
import * as React from 'react';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import logo from '../../assets/be-vip.svg';
import routes from '../../routes';
import {logout} from '../../store/actions/auth';
import AddOn from './screens/AddOn';
import AddOns from './screens/AddOns';
import Affiliate from './screens/Affiliate';
import Affiliates from './screens/Affiliates';
import Archive from './screens/Archive';
import CheckIns from './screens/CheckIns';
import Competition from './screens/Competition';
import Competitions from './screens/Competitions';
import Dashboard from './screens/Dashboard';
import Event from './screens/Event';
import Events from './screens/Events';
import Management from './screens/Management';
import Tier from './screens/Tier';
import Tiers from './screens/Tiers';
import Town from './screens/Town';
import Towns from './screens/Towns';
import User from './screens/User';
import Users from './screens/Users';

const Admin = (props) => {
  const user = useSelector(state => state['auth'].user);
  const dispatch = useDispatch();

  const userCan = useCallback((permission, can = null, cant = null) => {
    if (user.permissions[`can_${permission}`]) {
      return can;
    }
    return cant;
  }, [user]);

  const userCanOrRedirect = useCallback((permission, can = null) => {
    const hasPermission = userCan(permission, can);
    return hasPermission ?? <Redirect to={`${routes.dashboard}`}/>;
  }, [userCan]);

  return (
    <Authenticated
      title="Be VIP"
      logo={(
        <Box marginY={1} display="flex" alignItems="center" justifyContent="center">
          <img
            src={logo}
            alt="Be VIP"
            style={{width: '40%'}}/>
        </Box>
      )}
      userName={user ? user.first_name : ''}
      nav={[
        {title: 'Dashboard', route: routes.dashboard, icon: <DashboardIcon/>},
        ...userCan('manage_affiliates', [
          {title: 'Affiliates', route: routes.affiliates.index, icon: <Apartment/>},
          {title: 'Towns', route: routes.towns.index, icon: <Map/>}
        ], []),
        ...userCan('manage_users', [
          {title: 'Check-ins', route: routes.checkIns, icon: <LocationOn/>}
        ], []),
        ...userCan('manage_competitions', [
          {title: 'Competitions', route: routes.competitions.index, icon: <CreditCard/>}
        ], []),
        ...userCan('manage_events', [
          {title: 'Events', route: routes.events.index, icon: <CalendarToday/>}
        ], []),
        ...userCan('manage_users', [
          {title: 'Users', route: routes.users.index, icon: <ContactMail/>},
          {title: 'Administrators', route: routes.administrators.index, icon: <Security/>}
        ], []),
        ...userCan('manage_subscription_tiers', [
          {title: 'Tiers', route: routes.tiers.index, icon: <StarBorder/>},
          {title: 'Add-ons', route: routes.addOns.index, icon: <SwitchAccessShortcutAdd/>}
        ], []),
        ...userCan('change_settings', [{
          title: 'Management',
          route: routes.management,
          icon: <Storage/>
        }], []),
        ...userCan('manage_affiliates', [
          {title: 'Archive', route: routes.archive, icon: <ArchiveIcon/>}
        ], []),
      ]}
      onLogout={() => dispatch(logout())}
    >
      <Switch>

        <Route
          exact
          path={routes.dashboard}
          render={routeProps => <Dashboard {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.affiliates.index}
          render={routeProps => userCanOrRedirect('manage_affiliates', <Affiliates {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.affiliates.detail}
          render={routeProps => userCanOrRedirect('manage_affiliates', <Affiliate {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.towns.index}
          render={routeProps => userCanOrRedirect('manage_affiliates', <Towns {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.towns.detail}
          render={routeProps => userCanOrRedirect('manage_affiliates', <Town {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.checkIns}
          render={routeProps => userCanOrRedirect('manage_users', <CheckIns {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.competitions.index}
          render={routeProps => userCanOrRedirect('manage_competitions', <Competitions {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.competitions.detail}
          render={routeProps => userCanOrRedirect('manage_competitions', <Competition {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.events.index}
          render={routeProps => userCanOrRedirect('manage_events', <Events {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.events.detail}
          render={routeProps => userCanOrRedirect('manage_events', <Event {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.users.index}
          render={routeProps => userCanOrRedirect('manage_users', <Users {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.users.detail}
          render={routeProps => userCanOrRedirect('manage_users', <User {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.administrators.index}
          render={routeProps => userCanOrRedirect('manage_users', <Users {...props} {...routeProps} admin={true}/>)}/>
        <Route
          exact
          path={routes.administrators.detail}
          render={routeProps => userCanOrRedirect('manage_users', <User {...props} {...routeProps} admin={true}/>)}/>

        <Route
          exact
          path={routes.tiers.index}
          render={routeProps => userCanOrRedirect('manage_subscription_tiers', <Tiers {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.tiers.detail}
          render={routeProps => userCanOrRedirect('manage_subscription_tiers', <Tier {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.addOns.index}
          render={routeProps => userCanOrRedirect('manage_subscription_tiers', <AddOns {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.addOns.detail}
          render={routeProps => userCanOrRedirect('manage_subscription_tiers', <AddOn {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.management}
          render={routeProps => userCanOrRedirect('change_settings', <Management {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.archive}
          render={routeProps => userCanOrRedirect('manage_affiliates', <Archive {...props} {...routeProps} />)}/>

      </Switch>
    </Authenticated>
  );
};

export default Admin;
