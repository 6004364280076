import {Checkbox, Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({country = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={country}
      prefix={prefix}
      panes={[
        {
          title: 'Country Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="code"
              prefix={prefix}
              label="Code"
              rules={{required: 'Please enter a country code'}}
            />
          ]
        },
        {
          title: 'Country Settings',
          fields: [
            <Checkbox
              name="is_default"
              prefix={prefix}
              label="Default Country?"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Country Details'}
    >{children}</Panes>
  );
};

export default Fields;
