import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';

const Events = ({archive = false}) => (
  <div>
    <Breadcrumbs crumbs={[{title: 'Events'}]}/>
    <Paper>
      <Table archive={archive}/>
    </Paper>
  </div>
);

export default Events;
