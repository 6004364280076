import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ContactFields from './ContactFields';
import CoreFields from './CoreFields';
import LocationFields from './LocationFields';

const AffiliateForm = forwardRef(({affiliate, fieldSet, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={affiliate}
      type="affiliate"
      fieldsComponent={useCallback((props) => {
        const Fields = AffiliateForm.fieldSets[fieldSet ?? 'core'].fields;
        return <Fields {...props}/>
      }, [fieldSet])}
      onSave={({id = null, ...update}) => services.affiliate.saveAffiliate(
        {id, ...AffiliateForm.fieldSets[fieldSet ?? 'core'].onSave(update)}
      )}
      onSaved={onSaved}
    />
  );
});

AffiliateForm.fieldSets = {
  core: {fields: CoreFields, onSave: ({name, type_id, description, promotions}) => ({name, type_id, description, promotions})},
  location: {
    fields: LocationFields,
    onSave: ({address1, address2, town_id, region, postcode, country_id}) => ({
      address1,
      address2,
      town_id,
      region,
      postcode,
      country_id
    })
  },
  contact: {
    fields: ContactFields,
    onSave: ({phone, email, website, facebook, twitter, instagram}) => ({
      phone,
      email,
      website,
      facebook,
      twitter,
      instagram
    })
  }
};

export default AffiliateForm;
