import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';

const Users = ({admin = false, archive = false}) => (
  <div>
    <Breadcrumbs crumbs={[{title: admin ? 'Administrators' : 'Users'}]}/>
    <Paper>
      <Table admin={admin} archive={archive}/>
    </Paper>
  </div>
);

export default Users;
