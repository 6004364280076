import {DataTable, date, SquareButton} from '@management-ui/core';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Grid} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import EventForm from '../../forms/EventForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const goToDetail = useCallback((event) => {
    history.push(reverse(routes.events.detail, {id: event.id}));
  }, [history]);

  const handleRestore = useCallback((selected) => {
    setLoading(true);
    services.event.restoreEvent(selected).then(() => {
      setLoading(false);
      tableRef.current.refresh();
    }).catch(() => setLoading(false));
  }, [services]);

  return (
    <DataTable
      ref={tableRef}
      title="Events"
      options={{search: false}}
      tableProps={{isLoading: loading}}
      columns={useMemo(() => [
        {title: 'Title', field: 'title'},
        {title: 'Type', field: 'type', render: item => item.type?.name ?? '-'},
        {title: 'Date', field: 'date', render: item => item.date ? date(item.date) : '-'},
        {title: 'Time', field: 'time', render: item => item.time ?? '-'},
        {title: 'Town/City', field: 'town', render: item => item.town?.name ?? '-'},
        {title: 'Affiliate', field: 'affiliate', render: item => item.affiliate?.name ?? '-'},
        ...(archive ? [
          {
            title: 'Restore',
            field: 'restore',
            sorting: false,
            width: 120,
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (item) => (
              <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                <SquareButton
                  tooltip="Restore"
                  icon={<RestoreFromTrashIcon/>}
                  onClick={() => handleRestore(item)}
                />
              </Grid>
            )
          }
        ] : [])
      ], [archive, handleRestore])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.event.getTableEvents(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, archive])}
      newForm={archive ? null : {
        title: 'Add New Event',
        render: (props) => <EventForm event={{}} {...props}/>,
        onSaved: goToDetail
      }}
      onRowClick={archive ? null : goToDetail}
    />
  );
};

export default Table;
