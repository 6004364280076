import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({competition = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={competition}
      prefix={prefix}
      panes={[
        {
          title: 'Competition Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title'}}
            />,
            <TextField
              name="content"
              prefix={prefix}
              label="Content"
              fieldProps={{multiline: true, rows: 6}}
            />,
            <TextField
              name="link"
              prefix={prefix}
              label="Link"
            />,
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Competition Details'}
    >{children}</Panes>
  );
};

export default Fields;
