import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import AffiliateTypeForm from '../../forms/AffiliateTypeForm';

const AffiliateTypes = () => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Affiliate Types"
      options={{search: false, draggable: false}}
      columns={useMemo(() => [
        {title: 'Name', field: 'name', sorting: false}
      ], [])}
      loadData={query => new Promise((resolve, reject) => {
        services.affiliateType.getTableTypes(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Type',
        render: (props) => <AffiliateTypeForm type={{}} {...props}/>
      }}
      editForm={{
        render: (type, props) => <AffiliateTypeForm type={type} {...props}/>
      }}
    />
  );
};

export default AffiliateTypes;
