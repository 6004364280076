import {Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

const Fields = ({town = {}, prefix = '', multiplePanes = true, children}) => {
  const {setValue, /** @var {function(string): string} */watch} = useFormContext();

  const countries = useOptions('countries', useCallback(countries => countries.map(({name, id, is_default}) => ({
    title: name,
    value: id,
    is_default
  })), []));
  const countryName = useMemo(() => `${prefixWithSeparator(prefix)}country_id`, [prefix]);
  const country = watch(countryName);

  useEffect(() => {
    if (town) {
      if (countries) {
        if (!country) {
          if (town.country) {
            setValue(countryName, town.country.id);
          } else if (countries) {
            setValue(countryName, countries.find(({is_default}) => is_default)?.value);
          }
        }
      }
    }
  }, [town, countries, countryName, country, setValue]);

  return (
    <Panes
      entity={town}
      prefix={prefix}
      panes={[
        {
          title: 'Town Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <Select
              name="country_id"
              prefix={prefix}
              options={countries ?? []}
              label="Country"
            />
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Town Details'}
    >{children}</Panes>
  );
};

export default Fields;
