import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {Alert, CircularProgress, Snackbar} from '@mui/material';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {isFunction} from '../utilities';
import {useFormStyles} from './styles';
import {splitFields} from './utilities';

/**
 * A component to manage data entry.
 *
 * **Methods:**
 *
 * `save() : void` - Trigger the handleSubmit method
 *
 * `getData() : object` - Retrieve all values stored in the form
 *
 * `showError(string message) : void` - Show an error through the form's error snackbar
 *
 *
 * @module BaseForm
 *
 * @param {object} entity The source data being used
 * @param {string} type A string to indicate the type of data being edited
 * @param {string} entityKey An alternative to type in case 2 forms with the same type are active
 * @param {function} fieldsComponent Either a component or a function which returns a component to render the
 * form fields
 * @param {function} onSave A function to be called on save, should return a `Promise`
 * @param {function} onSaved A function called when the save is complete
 *
 * @example
 * <BaseForm
 *   entity={{name: 'Name'}}
 *   type="person"
 *   fieldsComponent={(props) => <Fields {...props} />}
 *   onSave={(data) => new Promise(resolve => resolve(data))}
 *   onSaved={(saved) => console.log(saved)}
 * />
 *
 */
const BaseForm = forwardRef(({entity, type, entityKey = null, fieldsComponent, onSave, onSaved}, ref) => {
  const classes = useFormStyles();

  const getDefaultError = useCallback((entityType) => {
    return `There was a problem saving the ${entityType}`;
  }, []);

  const loadingCount = useRef(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [prefix, setPrefix] = useState('');
  const methods = useForm();
  const {getValues, handleSubmit} = methods;

  const handleLoading = useCallback((loading) => {
    loadingCount.current += (loading ? 1 : -1);
    if (loadingCount.current < 0) {
      loadingCount.current = 0;
    }
    setLoading(loadingCount.current > 0);
  }, []);

  useEffect(() => {
    setErrorMessage(getDefaultError(type));
  }, [type, getDefaultError]);

  useEffect(() => {
    if (entity.id) {
      setPrefix(`${type}${entity.id}`);
    } else {
      setPrefix('');
    }
  }, [entity, type]);

  const onSubmit = useCallback(data => {
    const split = splitFields(data);
    const update = {...entity, ...(prefix ? split[prefix] : split)};
    handleLoading(true);
    onSave(update)
      .then(saved => {
        handleLoading(false);
        onSaved(saved);
      })
      .catch((error) => {
        handleLoading(false);
        if (error) {
          setErrorMessage(getDefaultError(type));
          setErrorOpen(true);
        }
      });
  }, [entity, type, prefix, onSave, onSaved, getDefaultError, handleLoading]);

  // noinspection JSUnusedGlobalSymbols
  useImperativeHandle(ref, () => ({
    save() {
      if (!loading) {
        handleSubmit(onSubmit)();
      }
    },

    getData() {
      return getValues();
    },

    showError(message) {
      setErrorMessage(message);
      setErrorOpen(true);
    }
  }));

  const fieldProps = {prefix, multiplePanes: true};
  fieldProps[entityKey ?? type] = entity;
  const makeFields = isFunction(fieldsComponent) ? fieldsComponent : (props) => <fieldsComponent {...props}/>;
  return (
    <FormProvider {...methods}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
          {loading ? <div className={classes.loading}><CircularProgress/></div> : null}
          {makeFields(fieldProps, handleLoading)}
          <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
            <Alert onClose={() => setErrorOpen(false)} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        </form>
      </LocalizationProvider>
    </FormProvider>
  );
});

BaseForm.propTypes = {
  entity: PropTypes.object,
  type: PropTypes.string,
  entityKey: PropTypes.string,
  fieldsComponent: PropTypes.func,
  onSave: PropTypes.func,
  onSaved: PropTypes.func
};

export default BaseForm;
