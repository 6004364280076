import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const AffiliateImageForm = forwardRef(({affiliate, image = {}, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={image}
      type="image"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({image}) => services.affiliate.uploadImage(affiliate, image)}
      onSaved={onSaved}
    />
  );
});

export default AffiliateImageForm;
