import {
  ConfirmationDialog,
  date,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  name,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Cancel, Payment} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Link, Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useStripe} from '@stripe/react-stripe-js';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import FileUploader from '../../../../components/FileUploader';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import SubscriptionForm from '../../forms/SubscriptionForm';
import UserForm from '../../forms/UserForm';
import CheckIns from '../CheckIns/Table';
import AddOns from './AddOns';
import Permissions from './Permissions';
import Wrapper from './Wrapper';

const useStyles = makeStyles(() => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  }
}));

const User = ({admin = false}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({module: 'Sorry the user could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'start', 'cancel']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const loadUser = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.user.getUser(id).then(retrieved => {
        setUser(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('module', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadUser(match.params?.id);
    }
  }, [history, match, user, loadUser]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...(admin ? Wrapper.administratorCrumbs : Wrapper.userCrumbs)];
    if (user) {
      header = name(user);
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [user, admin]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.user.deleteUser(user).then(() => {
        setLoading(false);
        history.push(routes.users.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, user]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setUser(updated);
    } else if (user?.id) {
      loadUser(user.id);
    }
  }, [toggleDialog, setUser, loadUser, user]);

  const handleUpload = useCallback((image) => (
    services.user.uploadImage(user, image)
  ), [services, user]);

  const handleUploaded = useCallback((path) => {
    setUser({...user, image: path})
  }, [user]);

  const handleStart = useCallback(async ({tier_id}) => {
    toggleDialog('start', false);
    setLoading(true);
    const url = `${window.location.origin}${window.location.pathname}`;
    services.user.startSubscription(user, tier_id, url, `${url}?cancelled=1`).then((response) => {
      setLoading(false);
      stripe.redirectToCheckout({
        sessionId: response.session
      }).then((result) => {
        return Promise.reject(result.error.message);
      }).catch(() => null);
    }).catch(() => setLoading(false));
  }, [services, stripe, user, toggleDialog]);

  const handleCancel = useCallback((confirmed) => {
    toggleDialog('cancel', false);
    if (confirmed) {
      setLoading(true);
      services.user.cancelSubscription(user).then((updated) => {
        setUser(updated);
        setLoading(false);
      }).catch(() => setLoading(false));
    }
  }, [services, user, toggleDialog]);

  return (
    <Wrapper title={title} admin={admin} loading={loading} crumbs={crumbs} errors={errors}>
      {user?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="User Details"
              actions={[
                {title: 'Edit User', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete User', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Name', value: name(user)},
                {title: 'Email Address', value: user.email},
                {title: 'Telephone Number', value: user.phone ?? '-'},
                {title: 'Location', value: user.location ?? '-'},
                {title: 'Date of Birth', value: user.date_of_birth ? date(user.date_of_birth) : '-'},
                {title: 'Facebook', value: user.facebook ?? '-'},
                {title: 'Twitter', value: user.twitter ?? '-'},
                {title: 'Instagram', value: user.instagram ?? '-'},
                {
                  title: 'Saved Affiliates', value: user.affiliates.length ? (
                    <ul className={classes.list}>
                      {user.affiliates.map(({id, name}, index) => (
                        <li key={index}>
                          <Link component={RouterLink} to={reverse(routes.affiliates.detail, {id})}>{name}</Link>
                        </li>
                      ))}
                    </ul>
                  ) : '-'
                },
                {title: 'Is Active?', value: <YesNo value={user.is_active}/>},
                {title: 'Created', value: dateTime(user.created_at)},
                {title: 'Last Updated', value: dateTime(user.updated_at)}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit User"
                    open={openDialogs['edit'] ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <UserForm
                        {...props}
                        user={user}
                        admin={admin}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete User"
                    message="Are you sure you want to delete this user? Doing so will delete all their information from the system"
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Profile Image"
              collapseChildrenHeight={false}
            >
              <Box paddingX={2}>
                <FileUploader
                  title=""
                  image={user.image}
                  prompt="Drop an image here or click to select one from your computer"
                  onUpload={handleUpload}
                  onUploaded={handleUploaded}
                />
              </Box>
            </DetailPane>,

            <DetailPane
              title="Subscription Details"
              actions={user.subscription ? [
                {title: 'Cancel Subscription', icon: <Cancel/>, onClick: () => toggleDialog('cancel', true)},
              ] : [
                {title: 'Start Subscription', icon: <Payment/>, onClick: () => toggleDialog('start', true)},
              ]}
              details={[
                {title: 'Tier', value: user.tier ? user.tier.name : '-'},
                {title: 'Expires', value: user.expires_at ? date(user.expires_at) : '-'},
                {title: 'Stripe Subscription', value: user.subscription ?? '-'}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Start Subscription"
                    open={openDialogs['start'] ?? false}
                    onClose={() => toggleDialog('start', false)}
                    maxWidth="sm"
                    render={(props) => (
                      <SubscriptionForm
                        {...props}
                        user={user}
                        subscription={{}}
                        onSave={handleStart}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['cancel'] ?? false}
                    title="Cancel Subscription"
                    message="Are you sure you want to cancel this user's subscription?"
                    onClose={handleCancel}
                  />
                )
              ]}
            />,
          ]}/>

          {admin ? (
            <DetailColumns columns={[
              <DetailPane title="Permissions" collapseChildrenHeight={false}>
                <Permissions user={user} onUpdated={setUser}/>
              </DetailPane>
            ]}/>
          ) : null}

          <Paper component={Box} marginTop={2}>
            <AddOns user={user} onUpdated={setUser} loading={loading} onLoading={setLoading}/>
          </Paper>

          <Paper component={Box} marginTop={2}>
            <CheckIns user={user}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
};

export default User;
