import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const ContactFields = ({affiliate = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={affiliate}
      prefix={prefix}
      panes={[
        {
          title: 'Contact & Social',
          fields: [
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone"
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
            />,
            <TextField
              name="website"
              prefix={prefix}
              label="Website Address"
            />,
            <TextField
              name="facebook"
              prefix={prefix}
              label="Facebook Link"
            />,
            <TextField
              name="twitter"
              prefix={prefix}
              label="Twitter Link"
            />,
            <TextField
              name="instagram"
              prefix={prefix}
              label="Instagram"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Affiliate Details'}
    >{children}</Panes>
  );
};

export default ContactFields;
