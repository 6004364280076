import {DataTable, dateTime, SquareButton} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {Close} from '@mui/icons-material';
import {Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import LinkAddOn from './LinkAddOn';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: theme.spacing(4),

    '& .MuiToolbar-root': {
      flex: 1,
      paddingRight: 0,
    },
  },
}));

const AddOns = ({user, onUpdated, loading, onLoading}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);

  const handleToggle = useCallback((addOn) => {
    onLoading(true);
    services.user.toggleAddOn(user, addOn).then(updated => {
      onUpdated(updated);
      onLoading(false);
    }).catch(() => onLoading(false));
  }, [user, onUpdated, onLoading, services]);

  // noinspection JSValidateTypes
  return (
    <DataTable
      title="Add-ons"
      components={{
        Toolbar: props => (
          <div className={classes.container}>
            <MTableToolbar {...props} classes={classes.toolbar}/>
            <LinkAddOn user={user} onToggle={handleToggle}/>
          </div>
        )
      }}
      options={{
        search: false,
        paging: false,
        draggable: false
      }}
      tableProps={{isLoading: loading}}
      columns={useMemo(() => [
        {title: 'Name', sorting: false, field: 'name'},
        {title: 'Date Added', sorting: false, field: 'created_at', render: (addOn) => dateTime(addOn.created_at)},
        {
          title: 'Remove',
          field: 'remove',
          sorting: false,
          width: 120,
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
          },
          render: (item) => (
            <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
              <SquareButton
                tooltip="Remove"
                icon={<Close/>}
                onClick={() => handleToggle(item)}
              />
            </Grid>
          )
        }
      ], [handleToggle])}
      canRefresh={false}
      data={user.add_ons}
    />
  );
}

export default AddOns;
