import {ServiceProvider} from '../../services/ServiceProvider';
import {
  SET_ADD_ONS,
  SET_AFFILIATE_TYPES,
  SET_COUNTRIES,
  SET_EVENT_TYPES,
  SET_LOADING,
  SET_TOWNS
} from '../reducers/options';

export function setOptionsLoading(loading) {
  return {
    type: SET_LOADING,
    loading
  };
}

function setCountries(countries) {
  return {
    type: SET_COUNTRIES,
    countries
  };
}

export function loadCountries() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().country
      .getCountries({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setCountries(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearCountries() {
  return (dispatch) => {
    dispatch(setCountries([]));
  }
}

function setTowns(towns) {
  return {
    type: SET_TOWNS,
    towns
  };
}

export function loadTowns() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().town
      .getTowns({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setTowns(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearTowns() {
  return (dispatch) => {
    dispatch(setTowns([]));
  }
}

function setAffiliateTypes(types) {
  return {
    type: SET_AFFILIATE_TYPES,
    types
  };
}

export function loadAffiliateTypes() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().affiliateType
      .getTypes({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setAffiliateTypes(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearAffiliateTypes() {
  return (dispatch) => {
    dispatch(setAffiliateTypes([]));
  }
}

function setEventTypes(types) {
  return {
    type: SET_EVENT_TYPES,
    types
  };
}

export function loadEventTypes() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().eventType
      .getTypes({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setEventTypes(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearEventTypes() {
  return (dispatch) => {
    dispatch(setEventTypes([]));
  }
}

function setAddOns(addOns) {
  return {
    type: SET_ADD_ONS,
    addOns
  };
}

export function loadAddOns() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().addOn
      .getAddOns({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setAddOns(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearAddOns() {
  return (dispatch) => {
    dispatch(setAddOns([]));
  }
}
