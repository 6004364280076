import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({destination = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={destination}
      prefix={prefix}
      panes={[
        {
          title: 'Destination Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Destination Details'}
    >{children}</Panes>
  );
};

export default Fields;
