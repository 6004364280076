import {Link} from '@mui/icons-material';
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {useOptions} from '../../../../hooks';

const LinkAddOn = ({user, onToggle}) => {
  const allAddOns = useOptions('addOns');
  const [addOns, setAddOns] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    if (user && allAddOns) {
      setAddOns([...allAddOns].filter(({id}) => (
        user.add_ons.filter((userAddOn) => userAddOn.id === id).length < 1
      )));
    }
  }, [user, allAddOns]);

  // noinspection JSValidateTypes
  return addOns.length ? (
    <>
      <Tooltip title="Link Add-on">
        <IconButton
          ref={menuRef}
          color="inherit"
          edge="end"
          onClick={() => setShowAdd(true)}
        >
          <Link/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuRef.current}
        open={showAdd}
        onClose={() => setShowAdd(false)}
      >
        {addOns.map((addOn, index) => (
          <MenuItem key={index} onClick={() => setShowAdd(false) || onToggle(addOn)}>
            {addOn.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
}

export default LinkAddOn;
