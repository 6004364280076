import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import DestinationForm from '../../forms/DestinationForm';

const Destinations = () => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Destinations"
      options={{search: false, draggable: false}}
      columns={useMemo(() => [
        {title: 'Name', field: 'name', sorting: false},
      ], [])}
      loadData={query => new Promise((resolve, reject) => {
        services.destination.getTableDestinations(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Destination',
        render: (props) => <DestinationForm destination={{}} {...props}/>
      }}
      editForm={{
        render: (destination, props) => <DestinationForm destination={destination} {...props}/>
      }}
    />
  );
};

export default Destinations;
