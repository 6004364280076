import {BaseForm} from '@management-ui/core';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const UserForm = forwardRef(({user, admin, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={user}
      type="user"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={useCallback((
        {
          id,
          first_name,
          last_name,
          email,
          phone,
          location,
          date_of_birth,
          facebook,
          twitter,
          instagram,
          tier_id,
          password,
          is_active
        }
      ) => services.user.saveUser({
        id,
        first_name,
        last_name,
        email,
        phone,
        location,
        date_of_birth: date_of_birth ? moment(date_of_birth).format('YYYY-MM-DD') : null,
        facebook,
        twitter,
        instagram,
        tier_id,
        password,
        is_active,
        is_admin: admin
      }), [services, admin])}
      onSaved={onSaved}
    />
  );
});

export default UserForm;
