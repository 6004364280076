export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_TOWNS = 'SET_TOWNS';
export const SET_ADD_ONS = 'SET_ADD_ONS';
export const SET_AFFILIATE_TYPES = 'SET_AFFILIATE_TYPES';
export const SET_EVENT_TYPES = 'SET_EVENT_TYPES';
export const SET_LOADING = 'SET_OPTIONS_LOADING';

const options = (
  state = {
    countries: [],
    towns: [],
    addOns: [],
    affiliateTypes: [],
    eventTypes: [],
    loadingCount: 0,
    loading: false
  },
  action,
) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries
      };
    case SET_TOWNS:
      return {
        ...state,
        towns: action.towns
      };
    case SET_ADD_ONS:
      return {
        ...state,
        addOns: action.addOns
      };
    case SET_AFFILIATE_TYPES:
      return {
        ...state,
        affiliateTypes: action.types
      };
    case SET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.types
      };
    case SET_LOADING:
      const count = state.loadingCount + (action.loading ? 1 : -1);
      return {
        ...state,
        loadingCount: count,
        loading: count > 0
      };
    default:
  }
  return state;
};

export default options;
