import {
  ConfirmationDialog,
  date,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import FileUploader from '../../../../components/FileUploader';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import EventForm from '../../forms/EventForm';
import Wrapper from './Wrapper';

const Event = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({event: 'Sorry the event could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['core', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);

  const loadEvent = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.event.getEvent(id).then(retrieved => {
        setEvent(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('event', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadEvent(match.params?.id);
    }
  }, [history, match, event, loadEvent]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (event) {
      header = event.title;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [event]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.event.deleteEvent(event).then(() => {
        setLoading(false);
        history.push(routes.events.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, event]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('core', false);
    if (updated) {
      setEvent(updated);
    } else if (event?.id) {
      loadEvent(event.id);
    }
  }, [toggleDialog, setEvent, loadEvent, event]);

  const handleUpload = useCallback((image) => (
    services.event.uploadImage(event, image)
  ), [services, event]);

  const handleUploaded = useCallback((path) => {
    setEvent({...event, image: path})
  }, [event]);

  return (
    <Wrapper title={title} event={event} loading={loading} crumbs={crumbs} errors={errors}>
      {event?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Core Details"
              actions={[
                {title: 'Edit Event', icon: <EditIcon/>, onClick: () => toggleDialog('core', true)},
                {title: 'Delete Event', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Title', value: event.title},
                {title: 'Type', value: event.type?.name ?? '-'},
                {title: 'Date', value: event.date ? date(event.date) : '-'},
                {title: 'Time', value: event.time ?? '-'},
                {title: 'Affiliate', value: event.affiliate?.name ?? '-'},
                {title: 'Town', value: event.town?.name ?? '-'},
                {title: 'Content', value: event.content ?? '-'}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Event"
                    open={openDialogs['core'] ?? false}
                    onClose={() => toggleDialog('core', false)}
                    render={(props) => (
                      <EventForm
                        {...props}
                        event={event}
                        fieldSet="core"
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete Event"
                    message="Are you sure you want to delete this event?"
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Image"
              collapseChildrenHeight={false}
            >
              <Box paddingX={2}>
                <FileUploader
                  title=""
                  image={event.image}
                  prompt="Drop an image here or click to select one from your computer"
                  onUpload={handleUpload}
                  onUploaded={handleUploaded}
                />
              </Box>
            </DetailPane>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(event.created_at)},
                {title: 'Last Updated', value: dateTime(event.updated_at)}
              ]}
            />,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Event;
