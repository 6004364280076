import * as qs from 'qs';
import {API} from './API';

export class AffiliateService {
  async getTableAffiliates(params, additional = {}, resource = 'affiliate') {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getAffiliates(query, page, length, resource);
  }

  async getAffiliates(query, page, length, resource = 'affiliate') {
    return (await API.getConnection())
      .get('affiliates', {
        params: {...query, ...{page, length, resource}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getAffiliate(id, archive = false) {
    return (await API.getConnection())
      .get(`affiliates/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveAffiliate(data, resource = 'affiliate') {
    let method = 'post';
    let url = 'affiliates';
    if (data.id) {
      method = 'put';
      url = `affiliates/${data.id}`;
    }
    return (await API.getConnection())
      [method](`${url}?resource=${resource}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteAffiliate(affiliate) {
    return (await API.getConnection())
      .delete(`affiliates/${affiliate.id}`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async restoreAffiliate(affiliate) {
    return (await API.getConnection())
      .put(`affiliates/${affiliate.id}/restore`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async uploadImage(affiliate, file) {
    const body = new FormData();
    body.append('image', file);
    return (await API.getConnection())
      .post(
        `affiliates/${affiliate.id}/images`,
        body,
        {headers: {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}}
      ).then(response => response.data.data).catch(error => API.handleError(error));
  }

  async deleteImage(affiliate, image) {
    return (await API.getConnection()).delete(`affiliates/${affiliate.id}/images/${image.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reorderImages(affiliate, images) {
    return (await API.getConnection())
      .put(`affiliates/${affiliate.id}/images`, {images})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
