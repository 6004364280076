import * as qs from 'qs';
import {clearTowns} from '../store/actions/options';
import {API} from './API';

export class TownService {
  async getTableTowns(params, additional = {}, resource = 'town') {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTowns(query, page, length, resource);
  }

  async getTowns(query, page, length, resource = 'town') {
    return (await API.getConnection())
      .get('towns', {
        params: {...query, ...{page, length, resource}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getTown(id, archive = false) {
    return (await API.getConnection())
      .get(`towns/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveTown(data, resource = 'town') {
    let method = 'post';
    let url = 'towns';
    if (data.id) {
      method = 'put';
      url = `towns/${data.id}`;
    }
    return (await API.getConnection())
      [method](`${url}?resource=${resource}`, data)
      .then(response => {
        API.store.dispatch(clearTowns());
        return response.data.data
      })
      .catch(error => API.handleError(error));
  }

  async uploadImage(town, file) {
    const body = new FormData();
    body.append('image', file);
    return (await API.getConnection())
      .post(
        `towns/${town.id}/upload`,
        body,
        {headers: {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}}
      ).then(response => response.data.path).catch(error => API.handleError(error));
  }

  async deleteTown(town) {
    return (await API.getConnection())
      .delete(`towns/${town.id}`)
      .then(() => API.store.dispatch(clearTowns()))
      .catch(error => API.handleError(error));
  }

  async restoreTown(town) {
    return (await API.getConnection())
      .put(`towns/${town.id}/restore`)
      .then(() => API.store.dispatch(clearTowns()))
      .catch(error => API.handleError(error));
  }
}
