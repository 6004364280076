import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';

const Affiliates = ({archive = false}) => (
  <div>
    <Breadcrumbs crumbs={[{title: 'Affiliates'}]}/>
    <Paper>
      <Table archive={archive}/>
    </Paper>
  </div>
);

export default Affiliates;
