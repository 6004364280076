import * as qs from 'qs';
import {API} from './API';

export class CheckInService {
  async getTableCheckIns(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getCheckIns(query, page, length);
  }

  async getCheckIns(query, page, length) {
    return (await API.getConnection())
      .get('check-ins', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }
}
