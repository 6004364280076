import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import FileUploader from '../../../../components/FileUploader';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import CompetitionForm from '../../forms/CompetitionForm';
import Wrapper from './Wrapper';

const Competition = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({competition: 'Sorry the competition could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['core', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [competition, setCompetition] = useState(null);

  const loadCompetition = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.competition.getCompetition(id).then(retrieved => {
        setCompetition(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('competition', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadCompetition(match.params?.id);
    }
  }, [history, match, competition, loadCompetition]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (competition) {
      header = competition.title;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [competition]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.competition.deleteCompetition(competition).then(() => {
        setLoading(false);
        history.push(routes.competitions.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, competition]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('core', false);
    if (updated) {
      setCompetition(updated);
    } else if (competition?.id) {
      loadCompetition(competition.id);
    }
  }, [toggleDialog, setCompetition, loadCompetition, competition]);

  const handleUpload = useCallback((image) => (
    services.competition.uploadImage(competition, image)
  ), [services, competition]);

  const handleUploaded = useCallback((path) => {
    setCompetition({...competition, image: path})
  }, [competition]);

  return (
    <Wrapper title={title} competition={competition} loading={loading} crumbs={crumbs} errors={errors}>
      {competition?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Core Details"
              actions={[
                {title: 'Edit Core Details', icon: <EditIcon/>, onClick: () => toggleDialog('core', true)},
                {title: 'Delete Competition', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Title', value: competition.title},
                {title: 'Content', value: competition.content ?? '-'},
                {title: 'Link', value: competition.link ?? '-', link: competition.link},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Core Details"
                    open={openDialogs['core'] ?? false}
                    onClose={() => toggleDialog('core', false)}
                    render={(props) => (
                      <CompetitionForm
                        {...props}
                        competition={competition}
                        fieldSet="core"
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete Competition"
                    message="Are you sure you want to delete this competition?"
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Image"
              collapseChildrenHeight={false}
            >
              <Box paddingX={2}>
                <FileUploader
                  title=""
                  image={competition.image}
                  prompt="Drop an image here or click to select one from your computer"
                  onUpload={handleUpload}
                  onUploaded={handleUploaded}
                />
              </Box>
            </DetailPane>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(competition.created_at)},
                {title: 'Last Updated', value: dateTime(competition.updated_at)}
              ]}
            />,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Competition;
