import {FormDialog, ReorderingPanel} from '@management-ui/core';
import {Visibility} from '@mui/icons-material';
import {reverse} from 'named-urls';
import numeral from 'numeral';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import SubscriptionTierForm from '../../forms/SubscriptionTierForm';

const Table = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [list, setList] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const initialised = useRef(false);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      setLoading(true);
      services.subscriptionTier.getTiers({}, 1, 100).then((response) => {
        setLoading(false);
        setTiers(response.data);
      }).catch(() => setLoading(false));
    }
  }, [services]);

  useEffect(() => {
    setList(tiers.map((tier) => ({
      entity: tier,
      attributes: [
        tier.name,
        tier.price ? `£${numeral(tier.price).format('0,0.00')}` : '-',
        tier.frequency
      ]
    })));
  }, [tiers]);

  const handleReorder = useCallback((reordered) => {
    setTiers(reordered);
    return services.subscriptionTier.reorderTiers(reordered.map((tier, index) => ({
      id: tier.id,
      index: index + 1
    })));
  }, [services]);

  const goToDetail = useCallback((tier) => (
    history.push(reverse(routes.tiers.detail, {id: tier.id}))
  ), [history]);

  return (
    <>
      <ReorderingPanel
        title="Subscription Tiers"
        items={list}
        loading={loading}
        onLoading={setLoading}
        controls={[
          {
            title: 'View Tier',
            icon: <Visibility/>,
            onClick: goToDetail
          }
        ]}
        onNew={() => setShowNew(true)}
        onReorder={handleReorder}
        onReordered={setTiers}
      />
      <FormDialog
        title={'Add New Tier'}
        open={showNew}
        maxWidth="md"
        onClose={() => setShowNew(false)}
        render={(props) => (
          <SubscriptionTierForm
            {...props}
            tier={{}}
            onSaved={goToDetail}
          />
        )}
      />
    </>
  );
}

export default Table;
