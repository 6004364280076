import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Paper} from '@mui/material';
import numeral from 'numeral';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Text from '../../../../components/Text';
import routes from '../../../../routes';
import SubscriptionTierForm from '../../forms/SubscriptionTierForm';
import Table from '../Users/Table';
import Wrapper from './Wrapper';

const Tier = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({tier: 'Sorry the tier could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['core', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [tier, setTier] = useState(null);

  const loadTier = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.subscriptionTier.getTier(id).then(retrieved => {
        setTier(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('tier', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadTier(match.params?.id);
    }
  }, [history, match, tier, loadTier]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (tier) {
      header = tier.name;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [tier]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.subscriptionTier.deleteTier(tier).then(() => {
        setLoading(false);
        history.push(routes.tiers.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, tier]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('core', false);
    toggleDialog('location', false);
    toggleDialog('contact', false);
    if (updated) {
      setTier(updated);
    } else if (tier?.id) {
      loadTier(tier.id);
    }
  }, [toggleDialog, setTier, loadTier, tier]);

  return (
    <Wrapper title={title} tier={tier} loading={loading} crumbs={crumbs} errors={errors}>
      {tier?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Tier Details"
              actions={[
                {title: 'Edit Tier Details', icon: <EditIcon/>, onClick: () => toggleDialog('core', true)},
                {title: 'Delete Tier', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Name', value: tier.name},
                {title: 'Price', value: tier.price ? `£${numeral(tier.price).format('0,0.00')}` : '-'},
                {title: 'Frequency', value: tier.frequency},
                {title: 'Features', value: tier.features ? <Text text={tier.features}/> : '-'},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Tier Details"
                    open={openDialogs['core'] ?? false}
                    onClose={() => toggleDialog('core', false)}
                    render={(props) => (
                      <SubscriptionTierForm
                        {...props}
                        tier={tier}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete Tier"
                    message="Are you sure you want to delete this tier?"
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(tier.created_at)},
                {title: 'Last Updated', value: dateTime(tier.updated_at)}
              ]}
            />,
          ]}/>


          <Paper component={Box} marginTop={2} width="100%">
            <Table tier={tier} admin={false}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Tier;
