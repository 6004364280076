import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import {EventAvailable, Flight, Nightlife} from '@mui/icons-material';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from 'react';
import routes from '../../../../routes';
import AffiliateTypes from './AffiliateTypes';
import Countries from './Countries';
import Destinations from './Destinations';
import EventTypes from './EventTypes';
import Settings from './Settings';

const TABS = [
  {
    id: 'settings',
    slug: 'settings',
    title: 'Settings',
    icon: <SettingsIcon/>,
    render: (props) => <Settings {...props} />
  },
  {
    id: 'countries',
    slug: 'countries',
    title: 'Countries',
    icon: <PublicIcon/>,
    render: (props) => <Countries {...props} />
  },
  {
    id: 'destinations',
    slug: 'destinations',
    title: 'Destinations',
    icon: <Flight/>,
    render: (props) => <Destinations {...props} />
  },
  {
    id: 'affiliateTypes',
    slug: 'affiliate-types',
    title: 'Affiliate Types',
    icon: <Nightlife/>,
    render: (props) => <AffiliateTypes {...props} />
  },
  {
    id: 'eventTypes',
    slug: 'event-types',
    title: 'Event Types',
    icon: <EventAvailable/>,
    render: (props) => <EventTypes {...props} />
  },
];

const Management = () => {
  const {tab, handleTab} = useTab(TABS, routes.management);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Management'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
};

export default Management;
