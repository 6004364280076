import React from 'react';
import {Panes, TextField} from '@management-ui/core';

const SettingsFields = ({settings = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={settings}
      prefix={prefix}
      panes={[
        {
          title: 'General Settings',
          fields: [
            <TextField
              name="sender"
              prefix={prefix}
              label="Trigger Email Sender"
            />,
            <TextField
              name="addOnRecipients"
              prefix={prefix}
              label="Add-on Purchase Recipients"
            />,
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Settings'}
    >{children}</Panes>
  );
};

SettingsFields.getValues = ({sender, addOnRecipients}) => ({sender, addOnRecipients});

export default SettingsFields;
