import React, {useCallback, useContext} from 'react';
import routes from '../../routes';
import logo from '../../assets/be-vip.svg';
import {Access as UIAccess} from '@management-ui/core';
import {ServiceContext} from '../../components/Services';
import {useDispatch} from 'react-redux';
import {login, resetPassword} from '../../store/actions/auth';

export default function Access(props) {
  const services = useContext(ServiceContext);
  const dispatch = useDispatch();

  const handleLogin = useCallback((email, password) => {
    return dispatch(login(email, password));
  }, [dispatch])

  const handleForgotPassword = useCallback((email) => {
    return services.auth.forgotPassword(email, `${window.location.origin}${routes.resetPassword}?token={TOKEN}`)
  }, [services]);

  const handleResetPassword = useCallback((email, password, token) => {
    return dispatch(resetPassword(email, password, token))
  }, [dispatch]);

  return (
    <UIAccess
      title="Be VIP"
      logo={logo}
      logoWidth={120}
      company="Be VIP"
      routes={{login: routes.login, forgotPassword: routes.forgotPassword, resetPassword: routes.resetPassword}}
      actions={{
        onLogin: handleLogin,
        onForgotPassword: handleForgotPassword,
        onResetPassword: handleResetPassword
      }}
      {...props}
    />
  );
}
