import * as qs from 'qs';
import {clearCountries} from '../store/actions/options';
import {API} from './API';

export class CountryService {
  async getTableCountries(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getCountries(query, page, length);
  }

  async getCountries(query, page, length) {
    return (await API.getConnection())
      .get('countries', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveCountry(data) {
    let method = 'post';
    let url = 'countries';
    if (data.id) {
      method = 'put';
      url = `countries/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearCountries());
        return response.data.data
      })
      .catch(error => API.handleError(error));
  }
}
