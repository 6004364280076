import * as qs from 'qs';
import {clearEventTypes} from '../store/actions/options';
import {API} from './API';

export class EventTypeService {
  async getTableTypes(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTypes(query, page, length);
  }

  async getTypes(query, page, length) {
    return (await API.getConnection())
      .get('event-types', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveType(data) {
    let method = 'post';
    let url = 'event-types';
    if (data.id) {
      method = 'put';
      url = `event-types/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearEventTypes());
        return response.data.data
      })
      .catch(error => API.handleError(error));
  }
}
