import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import {Apartment} from '@mui/icons-material';
import * as React from 'react';
import routes from '../../../routes';
import Affiliates from './Affiliates/Table';

const TABS = [
  {
    id: 'affiliates',
    slug: 'affiliates',
    title: 'Affiliates',
    icon: <Apartment/>,
    render: (props) => <Affiliates {...props} archive={true}/>
  },
];

export default function Archive() {
  const {tab, handleTab} = useTab(TABS, routes.archive);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Archive'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
