import {DateField, Panes, prefixWithSeparator, RelationAutocomplete, Select, TextField} from '@management-ui/core';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';
import {useOptions} from '../../../../hooks';

const Fields = ({event = {}, prefix = '', multiplePanes = true, children}) => {
  const services = useContext(ServiceContext);
  const {setValue, /** @var {function(string): string} */watch} = useFormContext();

  const types = useOptions('eventTypes', useCallback(types => types.map(({name, id}) => ({
    title: name,
    value: id
  })), []));
  const typeName = useMemo(() => `${prefixWithSeparator(prefix)}type_id`, [prefix]);
  const type = watch(typeName);

  useEffect(() => {
    if (event) {
      if (types) {
        if (!type) {
          if (event.type) {
            setValue(typeName, event.type.id);
          }
        }
      }
    }
  }, [event, types, typeName, type, setValue]);

  const towns = useOptions('towns', useCallback(towns => towns.map(({name, id}) => ({
    title: name,
    value: id
  })), []));
  const townName = useMemo(() => `${prefixWithSeparator(prefix)}town_id`, [prefix]);
  const town = watch(townName);

  useEffect(() => {
    if (event) {
      if (towns) {
        if (!town) {
          if (event.town) {
            setValue(townName, event.town.id);
          }
        }
      }
    }
  }, [event, towns, townName, town, setValue]);

  const handleReset = useCallback((subject) => {
    if (subject.affiliate) {
      subject.affiliate_id = `${subject.affiliate.id}`;
      subject.affiliate_name = subject.affiliate.name;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={event}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Event Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title'}}
            />,
            <Select
              name="type_id"
              prefix={prefix}
              options={types ?? []}
              label="Type"
            />,
            <DateField
              name="date"
              prefix={prefix}
              label="Date"
            />,
            <TextField
              name="time"
              prefix={prefix}
              label="Time"
            />,
            <RelationAutocomplete
              valueName="affiliate_id"
              titleName="affiliate_name"
              prefix={prefix}
              label="Affiliate"
              loadOptions={query => (
                services.affiliate.getAffiliates({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(affiliate => ({
                    title: affiliate.name,
                    value: affiliate.id
                  })))
                  .catch(() => [])
              )}
            />,
            <Select
              name="town_id"
              prefix={prefix}
              options={towns ?? []}
              label="Town / City"
            />,
            <TextField
              name="content"
              prefix={prefix}
              label="Content"
              fieldProps={{multiline: true, rows: 6}}
            />,
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Event Details'}
    >{children}</Panes>
  );
};

export default Fields;
