import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import FileUploader from '../../../../components/FileUploader';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import TownForm from '../../forms/TownForm';
import Wrapper from './Wrapper';

const Town = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({town: 'Sorry the town could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['core', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [town, setTown] = useState(null);

  const loadTown = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.town.getTown(id).then(retrieved => {
        setTown(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('town', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadTown(match.params?.id);
    }
  }, [history, match, town, loadTown]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (town) {
      header = town.name;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [town]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.town.deleteTown(town).then(() => {
        setLoading(false);
        history.push(routes.towns.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, town]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('core', false);
    if (updated) {
      setTown(updated);
    } else if (town?.id) {
      loadTown(town.id);
    }
  }, [toggleDialog, setTown, loadTown, town]);

  const handleUpload = useCallback((image) => (
    services.town.uploadImage(town, image)
  ), [services, town]);

  const handleUploaded = useCallback((path) => {
    setTown({...town, image: path})
  }, [town]);

  return (
    <Wrapper title={title} town={town} loading={loading} crumbs={crumbs} errors={errors}>
      {town?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Core Details"
              actions={[
                {title: 'Edit Town', icon: <EditIcon/>, onClick: () => toggleDialog('core', true)},
                {title: 'Delete Town', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {title: 'Name', value: town.name},
                {title: 'Country', value: town.country ? town.country.name : '-'},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Town"
                    open={openDialogs['core'] ?? false}
                    onClose={() => toggleDialog('core', false)}
                    render={(props) => (
                      <TownForm
                        {...props}
                        town={town}
                        fieldSet="core"
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs['delete'] ?? false}
                    title="Delete Town"
                    message="Are you sure you want to delete this town?"
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Image"
              collapseChildrenHeight={false}
            >
              <Box paddingX={2}>
                <FileUploader
                  title=""
                  image={town.image}
                  prompt="Drop an image here or click to select one from your computer"
                  onUpload={handleUpload}
                  onUploaded={handleUploaded}
                />
              </Box>
            </DetailPane>,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(town.created_at)},
                {title: 'Last Updated', value: dateTime(town.updated_at)}
              ]}
            />,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Town;
